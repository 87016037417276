import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

// import {Link} from 'react-router-dom';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {useNavigate} from "react-router-dom";

import ImageCropModal from './ImageCropModal';

function FileUploadContent() {

    let navigate = useNavigate();

    if (localStorage.getItem("tokenID")==null) {
        window.location.href="/login";
     }
  
     function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
     }
  
     let [msg,setMsg]=useState("");
     let [msgcode,setMsgcode]=useState("");
     let [msgcolor,setMsgcolor]=useState("");
  
     let [pic,setPic]=useState(null);
     let [document,setDocument]=useState(null);
     let [newpic,setNewpic]=useState(null);
     let [newdocument,setNewdocument]=useState(null);

     let [picapprove,setPicapprove]=useState(1);
     let [documentapprove,setDocapprove]=useState(1);
  
     let [isbtn,setIsbtn]=useState(true);
     let [isbtn2,setIsbtn2]=useState(true);
     
     async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
           var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
              headers: {
                 "Content-Type":"application/json",
                 "Authorization":"Bearer "+tokenval
              }
           });
           var data=await resp.json();
           setPic(data.pic);
           setDocument(data.document);
           setPicapprove(data.pic_approve);
           setDocapprove(data.document_approve);
           localStorage.setItem("pic",data.pic);
           setCroppedImage2("https://safalata.in/safalatalara/uploadpic/"+data.pic);
           setCroppedImage("https://safalata.in/safalatalara/uploadpic/"+data.document);

           } else {
                let path = `/`; 
                navigate(path);
           }
  
     }
  
     
     
    //  async function picupdate() {
        
    //     // if (flag==1) {
    //        alert(newpic.name)
    //        var fd=new FormData();
  
    //        fd.append("pic",newpic);
    //        fd.append("enrollid",localStorage.getItem("enrollid"));
    //        var resp=await axios.post("https://safalata.in/safalatalara/api/picupdate",fd);
    //        var data=resp.data;
    //        setMsg(data.msg);
    //        setMsgcode(data.msgcode);
    //        setMsgcolor(data.msgcolor);
  
    //        if (data.msgcode==1) {
    //            setIsbtn(true);
  
    //            window.scrollTo({
    //                top: 0,
    //                behavior: "smooth"
    //            });
    //            getToken();
    //        }
    //     // }
    //  }

     async function picupdate() {
        
            const blob2 = await fetch(croppedImage2).then((res) => res.blob()); // Convert URL to Blob
            const img = new File([blob2], 'photo.jpg', { type: 'image/jpeg' });
            // console.log("PHOTO==========>",file2);
            var fd=new FormData();

            // fd.append("pic",newpic);
            fd.append("pic",img);
            // fd.append("img",img);
            fd.append("croppedImage2",croppedImage2);
            fd.append("enrollid",localStorage.getItem("enrollid"));
            var resp=await axios.post("https://safalata.in/safalatalara/api/picupdate",fd);
            var data=resp.data;
            setMsg(data.msg);
            setMsgcode(data.msgcode);
            setMsgcolor(data.msgcolor);

            if (data.msgcode==1) {
                setIsbtn(true);
                setIsbtn2(true);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                getToken();
            }
        // }
     }

     async function docupdate() {
      
        const blob = await fetch(croppedImage).then((res) => res.blob()); // Convert URL to Blob
        const pdffile = new File([blob], 'document.jpg', { type: 'image/jpeg' });
        // console.log("FILE==========>",file);
        var fd=new FormData();
  
        fd.append("pdf",pdffile);
        fd.append("croppedImage",croppedImage);
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.in/safalatalara/api/docupdate",fd);
        var data=resp.data;
        setMsg(data.msg);
        setMsgcode(data.msgcode);
        setMsgcolor(data.msgcolor);
  
        if (data.msgcode==1) {
            setIsbtn(true);
            setIsbtn2(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            getToken();
        }
  
     }


     // CROPPING IMAGE
    const [imageSrc, setImageSrc] = useState(null);
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
            setOpen(true);
        };
        reader.readAsDataURL(file);
    };

    const handleCropComplete = async (croppedImage) => {
        setCroppedImage(croppedImage);
    //    console.log(croppedImage)
        // const blob = await fetch(croppedImage).then((res) => res.blob()); // Convert URL to Blob
        // const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
        // console.log("FILE==========>",file);
        setIsbtn2(false);
    };
    // CROPPING IMAGE

    // CROPPING IMAGE PHOTO
    const [imageSrc2, setImageSrc2] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [croppedImage2, setCroppedImage2] = useState(null);

    const handleFileChange2 = (e) => {
        const file2 = e.target.files[0];
        const reader2 = new FileReader();
        reader2.onload = () => {
            setImageSrc2(reader2.result);
            setOpen2(true);
        };
        reader2.readAsDataURL(file2);
    };

    const handleCropComplete2 = async (croppedImage2) => {
        setCroppedImage2(croppedImage2);
    //    console.log(croppedImage2)
        setIsbtn(false);
        
    };
    // CROPPING IMAGE PHOTO
  
     useEffect(()=>{
        getToken();
        getNostorage();
     },[])

    return(
        <>

        <Container>
            <Grid container py={3} >
                <Typography variant="h6" component="h6" pb={1} fontWeight="normal">Profile Picture Update</Typography>
                
            </Grid>

            <Grid container pb={2} px={3} >
                <Grid item xs={12}  p={0} color="white">
                    
                    {/* <img src={"https://safalata.in/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{width: '200px'}} /> */}
                    <Typography variant="div" component="div" pb={3}>
                    {/* <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(ev)=>{
                        setNewpic(ev.target.files[0]);
                        // alert(ev.target.files[0].name);
                        if (ev.target.files[0].name!=null) {
                        setIsbtn(false);
                        } else {
                        setIsbtn(true);
                        }
                    }} accept=".jpg,.jpeg"/> */}
                        <input type='file' id="image" className='form-control' accept="image/*" onChange={handleFileChange2} />
                        {croppedImage2 && <img src={croppedImage2} alt="Please Upload Profile Photo" class="img-fluid w-100" style={{ marginTop: 20, width: '100%' }} />}
                        <ImageCropModal
                        open={open2}
                        onClose={() => setOpen2(false)}
                        imageSrc={imageSrc2}
                        onCropComplete={handleCropComplete2}
                        />

                    </Typography>

                    <Button variant="contained" mb={3} disabled={isbtn} fullWidth className='btn-green' onClick={picupdate}>            
                    Update Profile Image
                    </Button> 

                </Grid>
                <Grid item xs={12}  pt={3} color="white">
                    

                    <Typography variant="div" component="div" pb={3}>
                    {/* <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(ev)=>{
                        setNewpic(ev.target.files[0]);
                        // alert(ev.target.files[0].name);
                        if (ev.target.files[0].name!=null) {
                        setIsbtn(false);
                        } else {
                        setIsbtn(true);
                        }
                    }} accept=".jpg,.jpeg"/> */}

                        <input type='file' id="image" className='form-control' accept="image/*" onChange={handleFileChange} />
                        {croppedImage && <img src={croppedImage} alt="Please Upload Identity Photo Proof" class="img-fluid w-100" style={{ marginTop: 20, width: '100%' }} />}
                        <ImageCropModal
                        open={open}
                        onClose={() => setOpen(false)}
                        imageSrc={imageSrc}
                        onCropComplete={handleCropComplete}
                        />

                    <Button variant="contained" mb={3} disabled={isbtn2} fullWidth className='btn-green' onClick={docupdate}>            
                    Update Identity Proof
                    </Button> 

                    </Typography>

                </Grid>

                {/* <Grid item xs={12}  p={0} color="white">   

                    <Button variant="contained" mb={3} disabled={isbtn} fullWidth className='btn-green' onClick={picupdate}>            
                    Update Profile Image
                    </Button> 


                </Grid> */}
            </Grid>

            
        </Container>

        </>
    );
}

export default FileUploadContent;







// import React  from "react";
// function IndexContent() {
//     return(
//         <>
//         asa

//         </>
//     );
// }

// export default IndexContent;